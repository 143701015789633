<template>
  <div>
    <v-card color="appBackGround" elevation="0" style="height: 785px">
      <v-card-title class="greyColor--text cus-h font-15 fw-600 pa-6">
        All Transactions
        <v-spacer></v-spacer>
        <div class="pt-0 ml-auto">
          <v-btn
            plain
            small
            @click="clearFilters"
            class="text-decoration-underline text-capitalize"
            v-if="
              status ||
              transaction_type ||
              type ||
              selectedTitle ||
              deleted_transaction
            "
          >
            Clear Filter
          </v-btn>
        </div>
        <div class="select-filter">
          <v-select
            item-text="name"
            v-model="deleted_transaction"
            :items="deletedTransactionFilter"
            item-value="value"
            placeholder="Deleted Status"
            hide-details
            outlined
            dense
            height="30px"
            width="100px"
            color="primary"
            item-color="primary"
            :menu-props="{
              contentClass: 'manu-custom-class',
              bottom: true,
              offsetY: true,
            }"
            :append-icon="
              deleted_transaction == null
                ? 'mdi-chevron-down'
                : 'mdi-chevron-up'
            "
            class="font-12 fw-500 mr-3 secondary--text search-filters search-filters-deleted Custom-10"
          ></v-select>
        </div>
        <div class="select-filter">
          <v-select
            item-text="name"
            v-model="transaction_type"
            :items="transactionTypeFilter"
            item-value="value"
            placeholder="Type"
            hide-details
            outlined
            dense
            height="30px"
            color="primary"
            item-color="primary"
            :menu-props="{
              contentClass: 'manu-custom-class',
              bottom: true,
              offsetY: true,
            }"
            :append-icon="
              transaction_type == null ? 'mdi-chevron-down' : 'mdi-chevron-up'
            "
            class="font-12 fw-500 mr-3 secondary--text search-filters Custom-10"
          >
          </v-select>
        </div>
        <div class="select-filter">
          <v-select
            item-text="name"
            v-model="status"
            :items="filteredStatusOptimized"
            item-value="value"
            placeholder="Status"
            hide-details
            outlined
            dense
            height="30px"
            item-color="#249DA8"
            :menu-props="{
              contentClass: 'manu-custom-class',
              bottom: true,
              offsetY: true,
            }"
            :append-icon="
              status == null ? 'mdi-chevron-down' : 'mdi-chevron-down'
            "
            class="font-12 fw-500 mr-3 secondary--text search-filters"
          ></v-select>
        </div>
        <div class="text-field-filter">
          <v-text-field
            v-model="referenceId"
            type="text"
            outlined
            hide-details
            dense
            height="30px"
            placeholder="Search by ref..."
            prepend-inner-icon="mdi-magnify"
            class="font-12 cus-ml mr-3 fw-500 secondary--text search-filters"
          ></v-text-field>
        </div>
        <custom-date-filter
          @typeChanged="typeChanged"
          :type="type"
          @clearSearch="dateCleared"
          @dateChange="dateChanged"
          class="text-field-filter mb-1 mr-3"
        >
          <span
            class="select-date text-capitalize"
            :class="name != null ? 'black--text' : ''"
          >
            {{ name === null ? "Select Date Range" : name }}
          </span>
          <v-img class="calender_icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path
                fill="grey"
                d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-1V1m-1 11h-5v5h5v-5Z"
              />
            </svg>
          </v-img>
        </custom-date-filter>
        <v-btn
          v-if="transactions.length > 0"
          color="primary"
          small
          height="30px"
          elevation="0"
          :loading="loading"
          @click="getTransactions('export')"
          class="textColor--text cus-m text-transform-none font-12 fw-500"
        >
          <v-img :src="require('/src/assets/icons/Share.svg')"></v-img>
          Export to CSV
        </v-btn>
      </v-card-title>
      <DataTable
        :customHeaders="headers"
        :data="transactions"
        :loading="isLoading"
        :customSlots="[
          'updated_at',
          'created_at',
          'created_at',
          'status',
          'detail',
          'type',
          'age',
          'thumbnails',
          'ip',
          'device_type',
        ]"
      >
        <template v-slot:status="{ item }">
          <div>
            <v-chip class="custom-chip" :class="getStatusClass(item)">
              {{ item.status_display_value }}
            </v-chip>
            <div v-if="item.deleted_at !== null" class="deleted-chip">
              Deleted
            </div>
          </div>
        </template>

        <template v-slot:type="{ item }">
          <v-chip
            :class="{
              'type-liveness custom-chip': item.type === 'liveness',
            }"
            v-if="item.type === 'liveness'"
          >
            Liveness
          </v-chip>
          <v-chip
            :class="{
              'type-idscan custom-chip': item.type === 'photo_id_match',
            }"
            v-if="item.type === 'photo_id_match'"
          >
            Face Match
          </v-chip>
          <v-chip
            :class="{
              'type-idscan custom-chip': item.type === 'face_search',
            }"
            v-if="item.type === 'face_search'"
          >
            Face Search
          </v-chip>
          <v-chip
            :class="{
              'type-idscan custom-chip': item.type === 'face_enrollment',
            }"
            v-if="item.type === 'face_enrollment'"
          >
            Face Enrollment
          </v-chip>
          <v-chip
            :class="{
              'type-idscan custom-chip': item.type === 'age_estimation',
            }"
            v-if="item.type === 'age_estimation'"
          >
            Age Estimation
          </v-chip>
          <v-chip
            :class="{
              'type-idscan custom-chip': item.type === 'account_deduplication',
            }"
            v-if="item.type === 'account_deduplication'"
          >
            Account Deduplication
          </v-chip>

          <v-chip
            :class="{
              'type-idscan custom-chip': item.type === 'video_image_match',
            }"
            v-if="item.type === 'video_image_match'"
          >
            Video Image Match
          </v-chip>
          <v-chip
            :class="{
              'type-idscan custom-chip': item.type === 'document_verification',
            }"
            v-if="item.type === 'document_verification'"
          >
            Document Verification
          </v-chip>
          <v-chip
            :class="{
              'type-idscan custom-chip': item.type === 'document_ocr',
            }"
            v-if="item.type === 'document_ocr'"
          >
            Document OCR
          </v-chip>
        </template>
        <template v-slot:age="{ item }">
          <div class="background-color-custom">
            <div class="outer-circle">
              <span class="set-age" v-if="item.age">
                <span class="outer-plus-icon">
                  <img
                    class="set-plus-icon"
                    src="../../assets/icons/age-plus.svg"
                  />
                </span>
                <span>{{ formattedAge(item.age) }}</span>
              </span>
              <span v-else> N/A </span>
            </div>
          </div>
        </template>
        <template v-slot:thumbnails="{ item }">
          <div class="above-image thumbnail-image">
            <v-img 
              v-if="
                item &&
                item.thumbnail_photo_id !== null &&
                isValidUrl(item.thumbnail_id_url) &&
                item.deleted_at === null
              "
              @click="openImageModal(item, 0)"
              class="thumbnail-image"
              :src="item.thumbnail_id_url"
              alt="Thumbnail"
              loading="lazy"
            ></v-img>
            <!-- item.contentType == 'image' && -->
            <v-img
              v-else-if="
                item &&
                item.quick_liveness_photo_id &&
                isValidUrl(item.quick_liveness_id_url) &&
                item.deleted_at === null
              "
              @click="openImageModal(item, 0)"
              class="thumbnail-image"
              :src="item.quick_liveness_id_url"
              alt="Thumbnail"
            ></v-img>

            <div v-else>
              <!-- Display 'N/A' when both thumbnail_photo_id and quick_liveness_photo_id are null or undefined -->
              N/A
            </div>
            <div
              v-if="
                item &&
                item.backside_thumbnail_id !== null &&
                isValidUrl(item.backside_thumbnail_url) &&
                item.deleted_at === null
              "
              class="image-container"
            >
              <v-img
                @click="openImageModal(item, 1)"
                class="thumbnail-image ml-2"
                :src="item.backside_thumbnail_url"
                alt="Thumbnail"
              ></v-img>
            </div>
            <div
              v-if="item && item.photo_id && item.deleted_at === null"
              class="image-container"
            >
              <v-img
                @click="openImageModal(item, 1)"
                class="thumbnail-image ml-2"
                :src="item.photo_id_url"
                alt="Thumbnail"
              ></v-img>
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.video_url && item.detail_liveness_exist"
                  @click="showVideo(item.video_url)"
                  >mdi-play-circle-outline</v-icon
                >
              </template>
              <span>Detail Liveness</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:ip="{ item }">
          <div
            class=""
            v-if="
              item &&
              item.transaction_log &&
              item.transaction_log !== null &&
              (item.transaction_log.country_code == 'UNDEFINED' ||
                item.transaction_log.country_code == '' ||
                item.transaction_log.country_code == 'Unknown' ||
                item.transaction_log.country_code == 'UNKNOWN') &&
              (item.transaction_log.ip == 'undefined' ||
                item.transaction_log.ip == null ||
                item.transaction_log.ip == 'Unknown') &&
              (item.transaction_log.device_type == 'UNDEFINED' ||
                item.transaction_log.device_type == '' ||
                item.transaction_log.device_type == 'Unknown')
            "
          >
            <span>N/A</span>
          </div>
          <div
            class="d-flex align-center"
            v-else-if="
              item &&
              item.transaction_log &&
              (item.transaction_log.country_code == 'UNDEFINED' ||
                item.transaction_log.country_code == '' ||
                item.transaction_log.country_code == 'Unknown' ||
                item.transaction_log.country_code == 'UNKNOWN') &&
              (item.transaction_log.ip !== 'undefined' ||
                item.transaction_log.ip !== null ||
                item.transaction_log.ip !== 'Unknown')
            "
          >
            <span>{{ item.transaction_log.ip }}</span>
          </div>
          <div class="d-flex justify-center align-center" v-else>
            <v-tooltip
              v-if="
                item &&
                item.transaction_log &&
                item.transaction_log.country_code !== 'UNDEFINED' &&
                item.transaction_log.country_code !== '' &&
                item.transaction_log.country_code != 'Unknown' &&
                item.transaction_log.ip !== 'undefined' &&
                item.transaction_log.ip !== '' &&
                item.transaction_log.ip !== 'Unknown'
              "
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex"
                >
                  <country-flag
                    :country="
                      item.transaction_log.country_code == 'UNDEFINED' &&
                      item.transaction_log.country_code == '' &&
                      item.transaction_log.country_code == null
                        ? ''
                        : item.transaction_log.country_code
                    "
                    size="normal"
                    class="elevation-1"
                  />
                </v-btn>
              </template>
              <span>
                {{
                  item?.transaction_log?.ip !== null
                    ? item.transaction_log.ip
                    : "N/A"
                }}</span
              >
            </v-tooltip>

            <v-tooltip
              max-width="400"
              v-if="
                item &&
                item.transaction_log &&
                item.transaction_log.device_type !== 'UNDEFINED' &&
                item.transaction_log.device_type !== '' &&
                item.transaction_log.device_type !== 'Unknown'
              "
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  color="transparent"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex justfy-center align-center pt-2 ml-3 cursor-pointer"
                >
                  <v-icon v-if="item.source === 'web'" size="20"
                    >mdi-remote-desktop
                  </v-icon>
                  <img
                    v-else
                    class="align-center"
                    src="@/assets/icons/sdk-monitor-svgrepo-com.svg"
                    height="20px"
                  />
                </span>
              </template>
              <div class="d-flex flex-column">
                <span v-if="item.source === 'web'"
                  ><strong>Browser Info: </strong
                  >{{ item.transaction_log.browser }}</span
                >
                <span
                  ><strong>Device Name: </strong
                  >{{ item && item.transaction_log.device_name }}</span
                >
                <span
                  ><strong>Device Type: </strong
                  >{{ item && item.transaction_log.device_type }}</span
                >
              </div>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:device_type="{ item }">
          {{
            item &&
            item.transaction_log &&
            item.transaction_log !== null &&
            item.transaction_log.device_type !== "undefined" &&
            item.transaction_log.device_type !== null
              ? item.transaction_log.device_type
              : "N/A"
          }}
        </template>
        <template v-slot:created_at="{ item }">
          <v-tooltip max-width="120" top>
            <template v-slot:activator="{ on }">
              <div class="date-time" v-on="on">
                <span class="show-time-created">{{
                  formatTransactionTime(item.created_at)
                }}</span>
                <span class="show-day-created">{{
                  formatTransactionDay(item.created_at)
                }}</span>
              </div>
            </template>
            <span>{{
              item.created_at | dateTimeConversionClientTimeZone
            }}</span>
          </v-tooltip>
        </template>
        <template v-slot:detail="{ item }">
          <div class="detail-btns">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  plain
                  v-bind="attrs"
                  v-on="on"
                  :to="{ path: '/transactions/' + item.reference_id }"
                  target="_blank"
                >
                  <v-icon size="20" class="icon-background">mdi-eye </v-icon>
                </v-btn>
              </template>
              <span>Details</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="item.deleted_at == null"
                  icon
                  plain
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteTransaction(item.reference_id)"
                >
                  <v-icon size="20" class="icon-delete">mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </div>
        </template>
      </DataTable>
      <div class="pagination-controls">
        <span class="show-pagination-numbers"
          >Viewing {{ getViewingRange() }}</span
        >
        <div>
          <v-btn
            @click="prevPage"
            :disabled="paginate.page === 1"
            class="text-capitalize pervious-pagination-btn"
          >
            <v-icon size="18">mdi-chevron-left</v-icon>
            Previous
          </v-btn>
          <v-btn
            @click="nextPageButton"
            :disabled="paginate.page === lastPage"
            class="next-pagination-btn ml-2 text-capitalize"
          >
            Next
            <v-icon size="18">mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </v-card>
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="imageArray"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>

    <v-dialog v-model="deleteModal" max-width="550" persistent>
      <v-card>
        <v-card-title class="text-h5"> Delete Transaction </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="font-12 fw-500 secondary--text mt-3">
          State Your Reason To Delete
          <span class="declineColor--text">{{ deleteReference }}</span>
          Transaction
        </v-card-text>

        <v-card-text class="font-12 fw-500 secondary--text">
          <v-textarea
            v-model="deleteReason"
            :counter="250"
            label="Reason"
            rows="4"
            :rules="reasonRule"
            outlined
            no-resize
            auto-grow
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :disabled="deleteReason.length <= 4 || deleteReason.length > 250"
            color="red darken-1"
            text
            :loading="btnLoader"
            @click="deleteTransaction('delete')"
          >
            Yes, Delete
          </v-btn>

          <v-btn color="blue darken-3" text @click="deleteModal = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- video player -->
    <v-dialog v-model="showVideoModal" max-width="550" persistent>
      <v-card class="video-container">
        <v-card-title class="font-16 fw-600 greyColor--text logout-title">
          Video Player
          <v-spacer></v-spacer>
          <v-icon class="font-20" @click="closeModal">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <video
          id="video"
          class="mt-2"
          muted
          autoplay
          height="360"
          width="550"
        ></video>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import DataTable from "../common/DataTable.vue";
import CountryFlag from "vue-country-flag";
import ApiService from "@/services/Api";
import CustomDateFilter from "../common/filters/CustomDateFilter.vue";
import { showErrorMessage, showNotificationBar } from "../../utils/showError";
import { ADMIN_API_URL } from "@/utils/env.js";
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "TransactionReports",
  components: { DataTable, CustomDateFilter, CountryFlag, VueEasyLightbox },
  data() {
    return {
      visible: false,
      index: 0,
      imageArray: [],
      modalImageUrl: "",
      baseUrl: ADMIN_API_URL,
      name: null,
      dialog: false,
      deleteModal: false,
      showVideoModal: false,
      deleteReference: "",
      deleteReason: "",
      reasonRule: [
        (value) => !!value || "Required.",
        (value) => (value || "").length <= 250 || "Max 250 characters",
      ],
      btnLoader: false,
      isLoading: false,
      loading: false,
      selectedTitle: "",
      status: null,
      title: "",
      startDate: null,
      endDate: null,
      type: null,
      transaction_type: null,
      referenceId: "",
      deleted_transaction: null,
      paginate: {
        page: 1,
        itemsPerPage: 10,
      },
      total: 0,
      lastPage: 0,
      headers: [
        {
          text: "Reference No",
          align: "start",
          sortable: false,
          value: "reference_id",
        },
        { text: "Agent info", value: "ip" },
        { text: "Image", value: "thumbnails", key: "image-thumbnails" },
        { text: "Transaction Type", value: "type" },
        { text: "Status", value: "status" },
        { text: "Date/Time", value: "created_at" },
        { text: "Details", value: "detail", width: "80px", align: "start" },
      ],
      transactions: [],
      statusFilters: [
        { name: "All", value: "all" },
        { name: "Initiated", value: "INITIATED" },
        { name: "Pending", value: "PENDING" },
        { name: "In Process", value: "INPROCESS" },
        { name: "Accepted", value: "ACCEPTED" },
        { name: "Liveness Passed", value: "LIVENESS PASSED" },
        { name: "Liveness Failed", value: "LIVENESS FAILED" },
        { name: "Face Matched", value: "FACE MATCHED" },
        { name: "Face Not Matched", value: "FACE NOT MATCHED" },
        { name: "Face Found", value: "FACE FOUND" },
        { name: "Declined", value: "DECLINED" },
        { name: "Face Enrolled", value: "FACE ENROLLED" },
        { name: "Face Not Enrolled", value: "FACE NOT ENROLLED" },
        { name: "Passed Verification", value: "PASSED VERIFICATION" },
        { name: "Failed Verification", value: "FAILED VERIFICATION" },
        { name: "Data Extracted ", value: "DATA EXTRACTED" },
        { name: "Data Not Extracted ", value: "DATA NOT EXTRACTED" },
        { name: "Timeout", value: "TIMEOUT" },
        { name: "Face Not Found", value: "NOT_FOUND" },
      ],
      deletedTransactionFilter: [
        { name: "All", value: "all" },
        { name: "Deleted", value: "true" },
        { name: "Not Deleted", value: "false" },
      ],
      transactionTypeFilter: [
        { name: "All", value: "all" },
        { name: "Liveness", value: "liveness" },
        { name: "Face Match", value: "photo_id_match" },
        { name: "Face Search", value: "face_search" },
        { name: "Face Enrollment", value: "face_enrollment" },
        { name: "Age Estimation", value: "age_estimation" },
        { name: "Document Verification", value: "document_verification" },
        { name: "Document OCR", value: "document_ocr" },
      ],
      dateFilters: [
        { name: "Today", value: "today" },
        { name: "Yesterday", value: "yesterday" },
        { name: "Last 7 days", value: "seven_days" },
        { name: "This Month", value: "this_month" },
        { name: "Last month", value: "last_month" },
        { name: "Last 3 months", value: "three_month" },
        { name: "Last 6 months", value: "six_month" },
        { name: "Last year", value: "last_year" },
        { name: "All time", value: "all_time" },
      ],
    };
  },
  created() {
    if (this.$route.query) {
      let { type, start_date, end_date } = this.$route.query;
      let matchingFilter = this.dateFilters.find(
        (filter) => filter.value === type
      );
      if (matchingFilter) {
        this.name = matchingFilter.name;
      } else {
        if (start_date || end_date) {
          this.dateChanged([start_date, end_date]);
        }
      }
    }
  },
  watch: {
    referenceId: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    $route: {
      immediate: true, // This will trigger the watcher when the component is initially created.
      handler() {
        if (this.$route.path.includes("transactions")) {
          this.getTransactions();
        }
      },
    },
    status: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    type: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    transaction_type: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    deleted_transaction: {
      handler() {
        this.getTransactionsOnPageBasis();
      },
    },
    paginate: {
      handler() {
        this.getTransactions();
      },
      deep: true,
    },
  },
  computed: {
    // Returns the filtered status options based on the current transaction type
    filteredStatusOptimized() {
      const statusFiltersMap = {
        all: [
          { name: "All", value: "all" },
          { name: "Initiated", value: "INITIATED" },
          { name: "Pending", value: "PENDING" },
          { name: "In Process", value: "INPROCESS" },
          { name: "Accepted", value: "ACCEPTED" },
          { name: "Liveness Passed", value: "LIVENESS PASSED" },
          { name: "Liveness Failed", value: "LIVENESS FAILED" },
          { name: "Face Matched", value: "FACE MATCHED" },
          { name: "Face Not Matched", value: "FACE NOT MATCHED" },
          { name: "Face Found", value: "FACE FOUND" },
          { name: "Declined", value: "DECLINED" },
          { name: "Face Enrolled", value: "FACE ENROLLED" },
          { name: "Face Not Enrolled", value: "FACE NOT ENROLLED" },
          { name: "Passed Verification", value: "PASSED VERIFICATION" },
          { name: "Failed Verification", value: "FAILED VERIFICATION" },
          { name: "Data Extracted ", value: "DATA EXTRACTED" },
          { name: "Data Not Extracted ", value: "DATA NOT EXTRACTED" },
          { name: "Timeout", value: "TIMEOUT" },
          { name: "Face Not Found", value: "NOT_FOUND" },
        ],
        liveness: [
          { name: "All", value: "all" },
          { name: "Initiated", value: "INITIATED" },
          { name: "Pending", value: "PENDING" },
          { name: "In Process", value: "INPROCESS" },
          { name: "Liveness Passed", value: "LIVENESS PASSED" },
          { name: "Liveness Failed", value: "LIVENESS FAILED" },
          { name: "Timeout", value: "TIMEOUT" },
          { name: "Face Not Found", value: "NOT_FOUND" },
        ],
        photo_id_match: [
          { name: "All", value: "all" },
          { name: "Initiated", value: "INITIATED" },
          { name: "Pending", value: "PENDING" },
          { name: "In Process", value: "INPROCESS" },
          { name: "Face Matched", value: "FACE MATCHED" },
          { name: "Face Not Matched", value: "FACE NOT MATCHED" },
          { name: "Timeout", value: "TIMEOUT" },
          { name: "Face Not Found", value: "NOT_FOUND" },
        ],
        face_search: [
          { name: "All", value: "all" },
          { name: "Initiated", value: "INITIATED" },
          { name: "Pending", value: "PENDING" },
          { name: "In Process", value: "INPROCESS" },
          { name: "Face Found", value: "FACE FOUND" },
          { name: "Face Not Found", value: "NOT_FOUND" },
          { name: "Declined", value: "DECLINED" },
          { name: "Timeout", value: "TIMEOUT" },
        ],
        face_enrollment: [
          { name: "All", value: "all" },
          { name: "Initiated", value: "INITIATED" },
          { name: "Pending", value: "PENDING" },
          { name: "In Process", value: "INPROCESS" },
          { name: "Face Enrolled", value: "FACE ENROLLED" },
          { name: "Face Not Enrolled", value: "FACE NOT ENROLLED" },
          { name: "Timeout", value: "TIMEOUT" },
          { name: "Face Not Found", value: "NOT_FOUND" },
        ],
        age_estimation: [
          { name: "All", value: "all" },
          { name: "Initiated", value: "INITIATED" },
          { name: "Pending", value: "PENDING" },
          { name: "In Process", value: "INPROCESS" },
          { name: "Passed Verification", value: "PASSED VERIFICATION" },
          { name: "Failed Verification", value: "FAILED VERIFICATION" },
          { name: "Timeout", value: "TIMEOUT" },
          { name: "Face Not Found", value: "NOT_FOUND" },
        ],
        document_verification: [
          { name: "All", value: "all" },
          { name: "Initiated", value: "INITIATED" },
          { name: "Pending", value: "PENDING" },
          { name: "In Process", value: "INPROCESS" },
          { name: "Data Extracted ", value: "DATA EXTRACTED" },
          { name: "Data Not Extracted ", value: "DATA NOT EXTRACTED" },
          { name: "Timeout", value: "TIMEOUT" },
          { name: "Document Not Found", value: "NOT_FOUND" },
        ],
        document_ocr: [
          { name: "All", value: "all" },
          { name: "Initiated", value: "INITIATED" },
          { name: "Pending", value: "PENDING" },
          { name: "In Process", value: "INPROCESS" },
          { name: "Data Extracted ", value: "DATA EXTRACTED" },
          { name: "Data Not Extracted ", value: "DATA NOT EXTRACTED" },
          { name: "Timeout", value: "TIMEOUT" },
          { name: "Document Not Found", value: "NOT_FOUND" },
        ],
      };

      return statusFiltersMap[this.transaction_type] || this.statusFilters;
    },
    // Sets the status value based on predefined mappings
    setStatusValue() {
      const statusMap = {
        "LIVENESS PASSED": "ACCEPTED",
        "LIVENESS FAILED": "DECLINED",
        "FACE MATCHED": "ACCEPTED",
        "FACE NOT MATCHED": "DECLINED",
        "FACE FOUND": "ACCEPTED",
        DECLINED: "DECLINED",
        "FACE ENROLLED": "ACCEPTED",
        "FACE NOT ENROLLED": "DECLINED",
        "PASSED VERIFICATION": "ACCEPTED",
        "FAILED VERIFICATION": "DECLINED",
        "DATA EXTRACTED": "ACCEPTED",
        "DATA NOT EXTRACTED": "DECLINED",
      };
      return statusMap[this.status] || this.status;
    },
    // Maps status to transaction type
    getTransactionsType() {
      const statusToTransactionType = {
        "LIVENESS PASSED": "liveness",
        "LIVENESS FAILED": "liveness",
        "FACE MATCHED": "photo_id_match",
        "FACE NOT MATCHED": "photo_id_match",
        "FACE FOUND": "face_search",
        DECLINED: "face_search",
        "FACE ENROLLED": "face_enrollment",
        "FACE NOT ENROLLED": "face_enrollment",
        "PASSED VERIFICATION": "age_estimation",
        "FAILED VERIFICATION": "age_estimation",
        "DATA EXTRACTED": "document_verification",
        "DATA NOT EXTRACTED": "document_verification",
      };

      return statusToTransactionType[this.status] || null;
    },
  },
  methods: {
    /**
     * Sets the 'visible' property to true, showing the component.
     */
    show() {
      this.visible = true;
    },

    /**
     * Sets the 'visible' property to false, hiding the component.
     */
    handleHide() {
      this.visible = false;
    },

    /**
     * Returns an object containing CSS classes based on the status of an item.
     * @param {Object} item - The item containing status information.
     * @returns {Object} - Object with CSS classes based on the status of the item.
     */
    getStatusClass(item) {
      return {
        "custom-age":
          item.status === "ACCEPTED" && item.type === "age_estimation",
        "status-pending": item.status === "PENDING",
        "status-declined": item.status === "DECLINED",
        "status-in-process": item.status === "INPROCESS",
        "status-initiated": item.status === "INITIATED",
        "status-timeouts": item.status === "TIMEOUT",
        "status-not-found": item.status === "NOT_FOUND",
        "status-accepted": item.status === "ACCEPTED",
      };
    },

    /**
     * Checks if a given URL is valid.
     * @param {string} url - The URL to be validated.
     * @returns {boolean} - True if the URL is valid, false otherwise.
     */
    isValidUrl(url) {
      // Regular expression for a simple URL validation
      const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      return urlRegex.test(url);
    },

    /**
     * Formats the age to have leading zeros if it's less than 10.
     * @param {number} age - The age to be formatted.
     * @returns {string} - The formatted age string.
     */
    formattedAge(age) {
      return age >= 10 ? age : `0${age}`;
    },

    /**
     * Deletes a transaction based on the reference ID and delete reason.
     * @param {string} ref - The reference ID of the transaction to be deleted.
     */
    deleteTransaction(ref) {
      if (ref !== "delete") {
        this.deleteReason = "";
        this.deleteReference = ref;
        this.deleteModal = true;
      } else {
        this.btnLoader = true;
        let data = {
          reference_id: this.deleteReference,
          delete_comment: this.deleteReason,
        };
        ApiService.PostRequest("/merchant/transaction-delete", data)
          .then((response) => {
            this.btnLoader = false;
            this.deleteModal = false;
            showNotificationBar("success", response.data.message);
            this.getTransactions();
          })
          .catch((error) => {
            this.btnLoader = false;
            showErrorMessage(error);
          });
      }
    },

    /**
     * Shows a video modal with the specified URL.
     * @param {string} url - The URL of the video to be displayed.
     */
    showVideo(url) {
      this.showVideoModal = true;
      setTimeout(() => {
        let videoTag = document.querySelector("#video");
        videoTag.src = url;
      }, 500);
    },

    /**
     * Closes the video modal and clears the video source.
     */
    closeModal() {
      let videoTag = document.querySelector("#video");
      videoTag.src = "";
      this.showVideoModal = false;
    },

    /**
     * Opens an image modal with the specified item and index.
     * @param {object} item - The item containing image URLs.
     * @param {number} index - The index indicating which image to display first.
     */
    openImageModal(item, index) {
      this.imageArray = [];
      let img1, img2;
      if (item && item.quick_liveness_photo_id) {
        img1 = `${item.quick_liveness_id_url}`;
        this.imageArray.push(img1);
      }
      if (item && item.photo_id) {
        img2 = `${item.photo_id_url}`;
        this.imageArray.push(img2);
      }
      else if (item && item.backside_thumbnail_id) {
        img2 = `${item.backside_thumbnail_url}`;
        this.imageArray.push(img2);
      }
      if (index === 1) {
        [this.imageArray[0], this.imageArray[1]] = [
          this.imageArray[1],
          this.imageArray[0],
        ];
      }
      this.show();
    },

    /**
     * Retrieves the viewing range based on the current pagination settings.
     * @returns {string} A string indicating the current viewing range.
     */
    getViewingRange() {
      const start = (this.paginate.page - 1) * this.paginate.itemsPerPage + 1;
      const end = Math.min(start + this.paginate.itemsPerPage - 1, this.total);
      return `${end} out of ${this.total}`;
    },

    /**
     * Handles the click event for the next page button, fetching the next page of transactions.
     */
    nextPageButton() {
      if (this.paginate.page < this.lastPage) {
        this.paginate.page += 1;
        this.getTransactions();
      }
    },

    /**
     * Handles the click event for the previous page button, fetching the previous page of transactions.
     */
    prevPage() {
      if (this.paginate.page > 1) {
        this.paginate.page -= 1;
        this.getTransactions();
      }
    },

    /**
     * Formats the transaction time based on the user's time zone.
     * @param {string} transactionTime - The transaction time in UTC format.
     * @returns {string} A formatted string representing the time elapsed since the transaction.
     */
    formatTransactionTime(transactionTime) {
      const userTimeZone = moment.tz.guess();
      const transactionMoment = moment.utc(transactionTime).tz(userTimeZone);

      const currentTime = moment();
      const duration = moment.duration(currentTime.diff(transactionMoment));
      const minutesAgo = duration.asMinutes();
      const hoursAgo = duration.asHours();

      if (minutesAgo < 60) {
        return `${Math.floor(minutesAgo)} minutes ago`;
      } else if (hoursAgo < 24) {
        return `${Math.floor(hoursAgo)} hours ago`;
      } else {
        return transactionMoment.format("HH:mm:ss A");
      }
    },

    /**
     * Formats the transaction day based on its proximity to the current date.
     * @param {string|Date} actualTime - The transaction's actual time.
     * @returns {string} A formatted string representing the transaction day.
     */
    formatTransactionDay(actualTime) {
      // Assuming actualTime is a valid date string or Date object
      const today = moment().startOf("day");
      const createdDate = moment(actualTime);

      // Check if the date is today
      if (createdDate.isSame(today, "day")) {
        return "Today";
      }

      // Check if the date is yesterday
      const yesterday = moment().subtract(1, "days").startOf("day");
      if (createdDate.isSame(yesterday, "day")) {
        return "Yesterday";
      }

      // For older dates, format as 'DD / MMM / YYYY'
      const formattedDate = createdDate.format("DD / MMM / YYYY");
      return formattedDate;
    },

    /**
     * Retrieves transactions based on the current page, resetting the page to 1 if it's not already.
     * If the page is already 1, it fetches transactions without resetting the page.
     */
    getTransactionsOnPageBasis() {
      if (this.paginate.page !== 1) this.paginate.page = 1;
      else this.getTransactions();
    },

    /**
     * Retrieves the content type (image, video, or unsupported) of a given URL.
     * @param {string} url - The URL to fetch and determine the content type.
     * @returns {Promise<string|null>} A promise resolving to the content type of the URL.
     */
    getContentType(url) {
      if (url === null) {
        return Promise.resolve(null);
      }
      return fetch(url, { method: "HEAD" })
        .then((response) => {
          const contentType = response.headers.get("Content-Type");
          if (contentType && contentType.startsWith("image/")) {
            return "image";
          } else if (contentType && contentType.startsWith("video/")) {
            return "video";
          } else {
            return "unsupported";
          }
        })
        .catch((error) => {
          console.error("Error fetching the URL:", error);
          return "error";
        });
    },

    /**
     * Retrieves transactions based on various parameters such as pagination, status, dates, and type.
     * If val is "export", exports transactions to a CSV file; otherwise, fetches transactions.
     * @param {string} val - Determines whether to fetch transactions or export them.
     */
    getTransactions(val) {
      this.selectedTitle = this.$route.query.selectedTitle;
      const queryParm = this.$route.query;
      if (this.transaction_type !== null) {
        this.selectedTitle = this.transaction_type;
      } else {
        this.transaction_type = this.selectedTitle;
        this.type = queryParm.type;
        this.startDate = queryParm.start_date;
        this.endDate = queryParm.end_date;
      }
      this.isLoading = true;

      let data = {
        pagination: this.paginate.itemsPerPage,
        page: this.paginate.page,
        reference_id: this.referenceId,
        status: this.setStatusValue,
        start_date: this.startDate,
        end_date: this.endDate,
        transaction_type:
          this.getTransactionsType !== null
            ? this.getTransactionsType
            : this.transaction_type,
        deleted: this.deleted_transaction,
        type: this.type,
      };
      if (val == "export") {
        data.export = true;
      }
      ApiService.PostRequest("/merchant/transactions", data)
        .then((response) => {
          if (val == "export") {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", "transactions.csv");
            document.body.appendChild(fileLink);
            fileLink.click();
            // Set isLoading to false after initiating the download
            this.isLoading = false;
            // Clean up the temporary elements
            // setTimeout(() => {
            //   document.body.removeChild(fileLink);
            //   window.URL.revokeObjectURL(fileURL);
            // }, 100);
          } else {
            const returnedData = response.data.result;
            // Promise.all(
            //   returnedData.data.map((item) =>
            //     this.getContentType(item.quick_liveness_id_url)
            //   )
            // ).then((contentTypes) => {
            //   this.transactions = returnedData.data.map((item, index) => {
            //     return {
            //       ...item,
            //       contentType: contentTypes[index],
            //     };
            //   });
            //   if (this.transactions && this.transactions.length > 0) {
            //       this.isLoading = false;
            //   }
            // });
            this.transactions = returnedData.data;
            this.total = returnedData.total;
            this.lastPage = returnedData.last_page;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          showErrorMessage(error);
          if (error.response.status === 401) {
            this.$store.dispatch("logout");
            this.$store.dispatch("SET_LOGOUT");
            this.$router.push("/");
          }
          // Set isLoading to false in case of an error
          this.isLoading = false;
        });
    },

    /**
     * Handles date changes and fetches transactions accordingly.
     * @param {Array} changedDate - An array containing the start and end dates of the custom range.
     */
    dateChanged(changedDate) {
      this.type = "custom_range";
      if (changedDate[0] <= changedDate[1]) {
        this.startDate = changedDate[0];
        this.endDate = changedDate[1];
      } else if (changedDate[0] >= changedDate[1]) {
        this.startDate = changedDate[1];
        this.endDate = changedDate[0];
      } else if (changedDate[1] == undefined) {
        this.startDate = changedDate[0];
        this.endDate = "";
      } else {
        (this.startDate = ""), (this.endDate = "");
      }
      this.name = this.startDate + " " + this.endDate;
      this.paginate.page = 1;
      this.getTransactions();
    },

    /**
     * Updates the transaction type and retrieves transactions accordingly.
     * @param {Object} type - An object containing the value and name of the transaction type.
     */
    typeChanged(type) {
      this.type = type.value;
      this.name = type.name;
      this.startDate = null;
      this.endDate = null;
      this.getTransactionsOnPageBasis();
    },

    /**
     * Clears the selected date range and retrieves transactions accordingly.
     */
    dateCleared() {
      this.name = null;
      this.type = null;
      this.getTransactionsOnPageBasis();
    },

    /**
     * Clears all filters and retrieves transactions accordingly.
     */
    clearFilters() {
      if (this.$route.query.selectedTitle) {
        this.selectedTitle = "";

        this.name = null;
        this.status = "";
        this.type = null;
        this.transaction_type = null;
        this.deleted_transaction = null;
        this.referenceId = "";
        this.startDate = null;
        this.endDate = null;
        this.paginate.page = 1;
        this.$router.push({
          path: "/transactions",
        });
        this.getTransactions();
      } else {
        this.name = null;
        this.status = "";
        this.type = null;
        this.transaction_type = null;
        this.deleted_transaction = null;
        this.referenceId = "";
        this.startDate = null;
        this.endDate = null;
        this.paginate.page = 1;

        this.getTransactions();
      }
    },
  },
};
</script>

<style>
.modal-img .v-image__image--cover {
  background-size: contain;
}
.modal-img .v-carousel__item {
  background: #e9e9e9;
}
.normal-flag {
  -webkit-transform: scale(0.4) !important;
  transform: scale(0.4) !important;
}
.calender_icon {
  padding-top: 5px;
  padding-left: 10px;
}
.detail-btns {
  display: flex;
  justify-content: start;
  align-items: baseline;
}
.icon-background {
  background-color: #f2f4f9 !important;
  border-radius: 15px !important;
  color: #213cc8 !important;
}
.icon-delete {
  color: #ff0505 !important;
  border-radius: 15px !important;
}
.above-image {
  display: flex;
}
.thumbnail-image {
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  cursor: pointer;
}
/* .vel-img {
  width: 444px !important;
  height: 255px !important;
} */
.background-color-custom {
  position: relative; /* Ensure relative positioning for absolute positioning inside */
}
.outer-circle {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #eeeef3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.outer-plus-icon {
  position: absolute;
  top: -1px;
  right: -4px;
  height: 8px;
  width: 10px;
  padding: 6px;
  border-radius: 50%;
  background-color: #eeeef3;
  border-top-right-radius: 90%;
  border-bottom-left-radius: 90%;
}
.set-plus-icon {
  position: absolute;
  top: 1px;
  right: 2px;
  height: 8px;
  background-color: #eeeef3;
  border-radius: 50%;
}
.set-age {
  position: relative;
  border: 1.5px solid #213cc8;
  border-radius: 50%;
  padding: 6px;
  background-color: #eeeef3;
  color: #213cc8;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.next-pagination-btn {
  border-radius: 6px !important;
  background: #213cc8 !important;
  height: 32px !important;
  padding: 10px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.pervious-pagination-btn {
  border-radius: 6px !important;
  border: 1px solid #e3e3e3 !important;
  background: #fff !important;
  height: 32px !important;
  padding: 10px !important;
  color: #0a2540 !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.pagination-controls {
  display: flex;
  justify-content: space-between;
  padding: 51px 20px 27px 20px;
  align-items: center;
}
.show-pagination-numbers {
  color: #292b30;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.date-time {
  display: flex;
  flex-direction: column;
}
.show-time-created {
  color: #0a2540;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.show-day-created {
  color: #979797;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.search-filters-date {
  border: 1px solid #eaecf3 !important;
  border-radius: 6px;
}

.search-filters-date .v-icon {
  font-size: 18px !important;
  color: #a0a6b4 !important;
}

.search-filters-date::before {
  background-color: transparent !important;
}

.search-filters fieldset {
  border: 1px solid #eaecf3 !important;
  border-radius: 6px;
}

.search-filters .v-input__slot {
  min-height: 30px !important;
}
.v-menu__content {
  min-width: 160px !important;
}
.select-filter .v-input__slot {
  width: 120px !important;
}

.text-field-filter .v-input__slot {
  width: 165px !important;
}

.search-filters .v-icon {
  font-size: 18px !important;
  color: #a0a6b4 !important;
  padding-bottom: 8px;
  transform: none !important;
}

.search-filters .v-icon::before {
  color: #a0a6b4 !important;
}

.manu-custom-class .v-list-item__action {
  margin-right: 5px !important;
}

.manu-custom-class .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 12px !important;
  font-weight: 400;
  color: #8990a2 !important;
}

.manu-custom-class .v-list-item__action {
  margin: 0px;
}

.v-input--selection-controls__input .v-icon {
  font-size: 18px !important;
  border-radius: 6px;
}

.manu-custom-class .v-list-item {
  min-height: 32px !important;
  padding: 0px 0px 0px 15px;
}

.manu-custom-class .v-list-item::before {
  background-color: #ffffff !important;
}

.v-menu__content {
  background: #ffffff !important;
  box-shadow: 0px 4px 14px rgba(137, 144, 162, 0.08);
  border-radius: 6px;
}

.search-filters .mdi-chart-line.v-icon {
  font-size: 15px !important;
}
.search-filters-deleted .v-input__slot {
  width: 140px !important;
}
.chip-styling {
  font-size: 12px !important;
  font-weight: 500;
}
.cus-h {
  line-height: 3rem !important;
}

@media (max-width: 496px) {
  .cus-ml {
    margin-left: -12px !important;
  }
}
@media (max-width: 390px) {
  .cus-m {
    margin-top: 7px;
  }
}
.deleted-chip {
  font-size: 10px !important;
  font-weight: 700;
  color: #e23b19 !important;
  margin-left: 15px;
}
.type-liveness {
  font-size: 12px !important;
  font-weight: 500;
  color: #4caf50 !important;
  background-color: #edf5ee !important;
}

.type-idscan {
  font-size: 12px !important;
  font-weight: 500;
  color: #7aa0da !important;
  background-color: #edf1f5 !important;
}
.details-header {
  padding-left: 30px !important;
}
.v-btn {
  text-transform: none !important;
}
.status-face-not-found-age {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #96700d !important;
  border-radius: 4px !important;
  background: rgba(238, 208, 128, 0.5) !important;
  padding: 3px 7px;
  font-style: normal !important;
  line-height: normal !important;
  display: inline-block; /* or display: block; */
  width: 104px;
}
.manu-custom-class .v-list-item__action {
  margin-right: 5px !important;
}

.manu-custom-class .v-list-item .v-list-item__content .v-list-item__title {
  font-size: 12px !important;
  font-weight: 400;
  color: #8990a2 !important;
}

.manu-custom-class .v-list-item__action {
  margin: 0px;
}

.manu-custom-class .v-list-item {
  min-height: 32px !important;
  padding: 0px 0px 0px 15px;
}
</style>
